import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import ReplayVideo from 'assets/icons/replay_video.svg'
import PlayVideo from 'assets/icons/play_video.svg'
import theme from 'stories/utils/theme'
import MuteVideoIcon from 'assets/homepage/mute_video.svg'
import PhoneOverlay from 'assets/homepage/phone_overlay.svg'
import { useTranslation } from 'next-i18next'
import { deviceSize } from 'lib/constants/GridBreakpoints'
import useMedia from 'lib/hooks/useMedia'

const VideoContainer = styled.div<{
    width: number
    height: number
    border?: string
    objectCover?: boolean
    showOverlay?: boolean
    isREM?: boolean
    isGIF?: boolean
}>`
    position: relative;
    width: ${({ width, isREM }) => `${width}${isREM ? 'rem' : 'px'}` ?? '480px'};
    height: ${({ height, isREM }) => `${height}${isREM ? 'rem' : 'px'}` ?? '600px'};
    z-index: ${theme.zIndex.player};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${theme.colors.black};
    border-radius: ${({ showOverlay }) => (showOverlay ? theme.borderRadius.huge : theme.borderRadius.normal)};
    img,
    video {
        border: ${({ border, showOverlay }) => (showOverlay ? `6px solid ${theme.colors.white}` : border ?? 'none')};
        border-radius: ${({ showOverlay }) => (showOverlay ? theme.borderRadius.huge : theme.borderRadius.normal)};
        object-fit: ${({ objectCover }) => (objectCover ? 'cover' : 'fill')};
        width: ${({ width, isREM }) => `${width}${isREM ? 'rem' : 'px'}` ?? '480px'};
        height: ${({ height, isREM }) => `${height}${isREM ? 'rem' : 'px'}` ?? '600px'};
    }
`

const ReplayIcon = styled(ReplayVideo)<{ width: number; height: number }>`
    position: absolute;
    top: ${({ height }) => `${height / 2}px` ?? '50%'};
    left: ${({ width }) => `${width / 2}px` ?? '50%'};
    cursor: pointer;
    z-index: ${theme.zIndex.playerIcons};
`

const PlayIcon = styled(PlayVideo)<{ width: number; height: number }>`
    position: absolute;
    top: ${({ height }) => `${height / 2}px` ?? '50%'};
    left: ${({ width }) => `${width / 2}px` ?? '50%'};
    cursor: pointer;
    z-index: ${theme.zIndex.playerIcons};
`

const MutedIcon = styled(MuteVideoIcon)`
    position: absolute;
    cursor: pointer;
    z-index: ${theme.zIndex.playerIcons};
    top: 0px;
    left: 0px;
`

const PhoneOverlayIcon = styled(PhoneOverlay)<{ width: number }>`
    position: absolute;
    cursor: pointer;
    z-index: ${theme.zIndex.playerIcons};
    top: 0;
    left: ${({ width }) => `${width / 2}px` ?? '50%'};
`

const FooterFilter = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 150px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(196, 196, 196, 0) 100%);
    border-radius: 15px;
    transform: rotate(-180deg);
    z-index: ${theme.zIndex.playerIcons};
`

interface Props {
    autoPlay?: boolean
    muted?: boolean
    loop?: boolean
    controls?: boolean
    width: number
    height: number
    source: string
    sourceType?: string
    replay?: boolean
    muteable?: boolean
    border?: string
    objectCover?: boolean
    addFilter?: boolean
    onEnded?: () => void
    showOverlay?: boolean
    isREM?: boolean
    isGIF?: boolean
}

const VideoPlayer = (props: Props) => {
    const {
        width,
        height,
        source,
        sourceType,
        addFilter,
        onEnded,
        replay,
        muteable,
        muted,
        border,
        objectCover,
        showOverlay,
        isREM,
        isGIF,
        ...rest
    } = props
    const [isPlaying, setIsPlaying] = useState(true)
    const [canReplay, setCanReplay] = useState(false)
    const [isMuted, setIsMuted] = useState(muted)
    const videoDiv = useRef<HTMLVideoElement>(null)
    const { t: tCommon } = useTranslation('common')
    const md = useMedia(deviceSize.md)

    const changeVideoState = () => {
        if (!videoDiv?.current) return
        if (isPlaying) {
            videoDiv.current.pause()
            setIsPlaying(false)
        } else {
            videoDiv.current.play()
            setIsPlaying(true)
        }
    }

    const replayVideo = () => {
        if (!videoDiv?.current) return
        videoDiv.current.pause()
        videoDiv.current.currentTime = 0
        videoDiv.current.play()
        setCanReplay(false)
    }

    const changeMuteVideo = () => setIsMuted((mute) => !mute)

    const onClick = () => {
        muteable && changeMuteVideo()
    }

    return (
        <VideoContainer
            width={width}
            height={height}
            border={border}
            objectCover={objectCover ?? false}
            showOverlay={showOverlay ?? false}
            className="video-wrapper"
            isREM={isREM}
        >
            {isGIF ? (
                <img width={width} height={height} src={source} onClick={onClick} alt="gif player" {...rest}></img>
            ) : (
                <video
                    width={width}
                    height={height}
                    ref={videoDiv}
                    onClick={onClick}
                    onEnded={() => {
                        setIsPlaying(false)
                        onEnded && onEnded()
                        replay && setCanReplay(true)
                    }}
                    onPause={() => setIsPlaying(false)}
                    onPlay={() => setIsPlaying(true)}
                    controlsList="nodownload"
                    muted={isMuted}
                    playsInline
                    {...rest}
                    src={source}
                    id="video"
                >
                    {tCommon('common/videoNotSupported')}
                </video>
            )}
            {!isGIF ? (
                <>
                    {replay && canReplay ? <ReplayIcon onClick={replayVideo} /> : null}
                    {!isPlaying && !canReplay ? <PlayIcon onClick={changeVideoState} /> : null}
                    {muteable && isMuted ? (
                        <MutedIcon onClick={changeMuteVideo} width={props.width} height={props.height} />
                    ) : null}
                    {addFilter ? <FooterFilter className="filter" /> : null}
                    {showOverlay && !md ? <PhoneOverlayIcon /> : null}
                </>
            ) : null}
        </VideoContainer>
    )
}

export default VideoPlayer
