import React, { useEffect, useState } from 'react'
import { LottieComponentProps } from 'lottie-react'
import styled from 'styled-components'
import dynamic from 'next/dynamic'

const Lottie = dynamic(() => import('lottie-react'), { ssr: false })

interface AnimationSize {
    width?: number
    height?: number
    isRelative?: boolean
    backgroundSize?: 'cover' | 'contain'
}

const AnimationComponent = styled(Lottie)<AnimationSize>`
    position: ${({ isRelative }) => (isRelative ? 'relative' : 'absolute')};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 0;
    width: ${({ width }) => (width ? `${width}px` : '100%')};
    height: ${({ height }) => (height ? `${height}px` : '100vh')};
    background-position: 50% 0;
    background-size: cover;
    overflow: hidden;
`

const PlaceholderWrapper = styled.div<AnimationSize>`
    position: ${({ isRelative }) => (isRelative ? 'relative' : 'absolute')};
    z-index: 0;
    width: ${({ width }) => (width ? `${width}px` : '100%')};
    height: ${({ height }) => (height ? `${height}px` : '100vh')};
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-size: ${({ backgroundSize }) => backgroundSize ?? 'cover'};
    overflow: hidden;
`

interface Props extends Omit<LottieComponentProps, 'animationData' | 'ref' | 'as'> {
    src: string
    placeholderSrc: string
    width?: number
    height?: number
    isRelative?: boolean
    backgroundSize?: 'cover' | 'contain'
}

const BackgroundAnimationWrapper = ({
    src,
    placeholderSrc: placeholder,
    height,
    width,
    isRelative,
    backgroundSize,
    ...rest
}: Props) => {
    const [animationData, setAnimationData] = useState<LottieComponentProps['animationData']>()

    useEffect(() => {
        import(`assets/animation/${src}`).then(setAnimationData)
    }, [src])

    if (!animationData)
        return (
            <PlaceholderWrapper
                style={{
                    backgroundImage: `url(${placeholder})`,
                }}
                height={height}
                width={width}
                isRelative={isRelative}
                backgroundSize={backgroundSize}
            />
        )
    return (
        <AnimationComponent animationData={animationData} {...rest} height={height} width={width} isRelative={isRelative} />
    )
}
export default BackgroundAnimationWrapper
