import theme from 'stories/utils/theme'
import styled, { css } from 'styled-components'

export interface FullPageProps {
    fullWidth?: boolean
    fullMobileHeight?: boolean
    hideMobileImage?: boolean
    alignImageToBottom?: boolean
    fitContent?: boolean
    color?: string
}

interface LayoutProps {
    isWide?: boolean
    backgroundColor?: string
}

export const RestructuredContainer = styled.div<{ isWide?: boolean }>`
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 15px;

    @media (max-width: ${({ isWide }) => (isWide ? 1050 : 1250)}px) {
        grid-template-columns: 1fr;
    }
`

export const ContainerMain = styled.div<{ backgroundColor?: string }>`
    background-color: ${({ backgroundColor }) => backgroundColor ?? theme.colors.white};
`

export const RestructuredMain = styled.div<LayoutProps>`
    @media (max-width: ${({ isWide }) => (isWide ? 750 : 1050)}px) {
        display: none;
    }
`

export const RestructuredSidebar = styled.div`
    padding: 0 15px;
    @media (max-width: 1050px) {
        display: none;
    }
`

export const RestructuredMobile = styled.div<LayoutProps>`
    background-color: ${({ backgroundColor }) => backgroundColor ?? 'transparent'};
    @media (min-width: ${({ isWide }) => (isWide ? 751 : 1051)}px) {
        display: none;
    }
`

export const ComponentContainer = styled.div<FullPageProps>`
    height: ${({ fullWidth }) => (fullWidth ? '100vh' : 'auto')};
    width: 100%;
    background-color: ${({ color }) => color ?? theme.colors.white};
    background-repeat: no-repeat;
    object-fit: contain;
    overflow: hidden;
    position: relative;
    background-position: ${({ alignImageToBottom }) => (alignImageToBottom ? 'bottom' : 'center')};
    .hide-mobile {
        display: none;
    }
    @media (max-height: 800px) {
        min-height: ${({ fitContent }) => (fitContent ? 'auto' : '800px')};
    }
    @media (max-width: 750px) {
        padding-top: 10px;
        height: ${({ fullMobileHeight }) => (fullMobileHeight ? '800px' : 'auto')};
        min-height: auto;
        .hide-mobile {
            display: block;
        }
        ${({ hideMobileImage }) =>
            hideMobileImage
                ? css`
                      background-image: none !important;
                  `
                : ''}
    }
`
